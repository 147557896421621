import { TextField } from "@mui/material";

import * as Yup from 'yup';
import { useFormik } from "formik";
import { BuySellCommission } from "../types";
import { NumericFormat } from "react-number-format";


const validationSchema = Yup.object({
    rate: Yup.string()
        .test(
            'is-number',
            'Введите число',
            (value) => {
                let schema = Yup.number();
                return schema.isValid(value);
            })
        .test(
            'is-positive',
            'Число должно быть больше 0',
            (value) => {
                let schema = Yup.number().positive('Число должно быть 0.');
                return schema.isValid(value);
            }
        )
        .required('Введите число.')
})

export function BuySellForm({
    commission, updateCommission
}: {
    commission?: BuySellCommission, updateCommission: (newCommission: BuySellCommission) => void,
}) {
    const formik = useFormik({
        initialValues: {
            rate: commission?.rate ?? 0,
        },
        validationSchema,
        onSubmit: () => console.log('buy sell')
    });

    return (
        <form target="_blank" onSubmit={formik.handleSubmit}>
            <NumericFormat
                value={formik.values.rate}
                onValueChange={(values) => {
                    formik.values.rate = Number(values.value);
                    updateCommission({ rate: formik.values.rate});
                }}
                allowNegative={false}
                allowedDecimalSeparators={[',']}
                thousandSeparator

                id="rate"
                name="rate"
                placeholder="Ставка"
                label="Ставка"
                required
                margin="normal"
                variant="outlined"
                size="small"
                sx={{ width: '150px' }}
                onBlur={formik.handleBlur}
                error={!!formik.touched.rate && !!formik.errors.rate}
                helperText={formik.touched.rate ? formik.errors.rate : null}

                customInput={TextField}
            />
        </form>
    )
}