import { useLoaderData } from "react-router-dom"

import { DealForm } from "./DealForm";
import { DeleteDealButton } from "./DeleteDealButton";
import { useEditDeal, useFetchDeal } from "../dealAPISlice";
import { Deal, FormDeal } from "../deal.dto";
import { Metadata } from "../../../api/api.dto";
import { CircularProgress } from "@mui/material";


export const EditDealForm = () => {
    const dealId = useLoaderData() as string;
    const { data } = useFetchDeal(dealId);

    const [editDeal, metadata] = useEditDeal();


    if (!data) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <>
            <DealForm
                deal={data}
                submitFunction={(formDeal: FormDeal) => {
                    editDeal(formDeal as Deal)
                }}
                metadata={metadata as Metadata}
            />
            <DeleteDealButton id={data.id} />
        </>
    )
}