import { ClientCard } from "./ClientCard";
import './ClientList.css';
import { SearchInput, useSearchByNameAndCode } from "../../../shared/ui/Search";
import { useFetchAllClients } from "../clientAPISlice";
import { Client } from "../client.dto";
import { CircularProgress } from "@mui/material";


export const ClientListConteiner = () => {
    const { data } = useFetchAllClients({}, {
        pollingInterval: 60000,
    });

    const { input, setInput, filter } = useSearchByNameAndCode();

    if (!data) {
        return (
            <CircularProgress 
                sx={{
                    margin: '200px auto'
                }}
            />
        )
    }

    return (
        <>
            <SearchInput input={{ input, setInput }} />
            <ClientListPresenter clients={filter(data)} />
        </>
    )
}

export const ClientListPresenter = ({ clients }: { clients: Client[] }) => {
    return (
        <div className="clientList">
            {
                clients.map(client => <ClientCard key={client.code} client={client} />)
            }
        </div>
    )
}