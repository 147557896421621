
export enum CommissionsType {
    BuySell = 'buy-sell',
    Swap = 'swap'
}

export const commissionsTypes = Array.from(Object.values(CommissionsType))

export interface CommissionPayload {
    commissionType: CommissionsType,
    code: string,
}

export type Commission = {
    value: BuySellCommission | SwapCommission
};

export type BuySellCommission = {
    rate: number
}

export type SwapCommissionItem = {
    lowerBound: number;
    upperBound: number | null;
    rate: number;
}
export type SwapCommission = SwapCommissionItem[];


export function mapCommissionToDisplay(commission: string) {
    switch (commission) {
        case CommissionsType.BuySell:
            return 'Buy Sell';
        case CommissionsType.Swap:
            return 'Swap';
        default:
            return 'Unknown';
    }
}