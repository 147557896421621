import { useLoaderData } from "react-router-dom";

import { ClientForm } from "./ClientForm"
import { DeleteClientButton } from "./DeleteClientButton";

import { useChangeClient, useFetchAllClients } from "../clientAPISlice";
import { Metadata } from "../../../api/api.dto";
import { Client } from "../client.dto";
import { ClientCommissions } from "../../commissions/CommissionClientCard";
import { CircularProgress } from "@mui/material";

export async function getClientIdLoader({ params }: any) {
    const clientCode = params.clientId;
    return clientCode as string;
}


export const EditClientForm = () => {
    const clientCode = useLoaderData() as string;
    const { data } = useFetchAllClients({});
    const client = data?.find(client => client.code === clientCode);

    const [changeClient, metadata] = useChangeClient();


    if (!client) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <>
            <ClientForm
                client={client}
                submitFunction={(client: Client) => changeClient({ code: clientCode, client })}
                metadata={metadata as Metadata}
            />
            <h3>Комиссии</h3>
            <ClientCommissions clientCode={client.code} />
            <DeleteClientButton
                code={clientCode}
            />
        </>
    )
}