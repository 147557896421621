import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReauth } from "../../api/apiHelpers";
import { CommissionPayload, Commission } from "./types";

export const commissionsAPI = createApi({
    reducerPath: 'commissions',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Commission'],
    endpoints: builder => ({

        getCommission: builder.query<Commission['value'], CommissionPayload>({
            query: (params) => ({
                url: `client/${params.code}/Commissions/${params.commissionType}`,
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
            providesTags: (_, __, params) => [{ type: 'Commission', id: `${params.code}_${params.commissionType}` }]
        }),

        createCommission: builder.mutation<unknown, Commission & CommissionPayload>({
            query: (params) => ({
                url: `client/${params.code}/Commissions/${params.commissionType}`,
                method: 'POST',
                body: JSON.stringify(params.value),
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
            invalidatesTags: (_, __, params) => [{ type: 'Commission', id: `${params.code}_${params.commissionType}` }]
        }),

        updateCommission: builder.mutation<unknown, Commission & CommissionPayload>({
            query: (params) => ({
                url: `client/${params.code}/Commissions/${params.commissionType}`,
                method: 'PATCH',
                body: JSON.stringify(params.value),
                headers: {
                    'Content-Type': 'application/json',
                }
            }),
            invalidatesTags: (_, __, params) => [{ type: 'Commission', id: `${params.code}_${params.commissionType}` }]
        }),

        deleteCommission: builder.mutation<unknown, CommissionPayload>({
            query: (params) => ({
                url: `client/${params.code}/Commissions/${params.commissionType}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_, __, params) => [{ type: 'Commission', id: `${params.code}_${params.commissionType}` }]
        })
    })
});

export const useGetCommission = commissionsAPI.endpoints.getCommission.useQuery;

export const useCreateCommission = commissionsAPI.endpoints.createCommission.useMutation;
export const useUpdateCommission = commissionsAPI.endpoints.updateCommission.useMutation;
export const useDeleteCommission = commissionsAPI.endpoints.deleteCommission.useMutation;