import { useState } from "react";

import { useFetchAllClients } from "../client/clientAPISlice";
import { ClientCodeInput } from "../client/ui/ClientInput";
import { CommissionsType } from "./types";
import { ClientCommissions } from "./CommissionClientCard";


export function CommissionWidget() {
    const { data, isLoading, error } = useFetchAllClients({});
    const [clientCode, setClientCode] = useState<string | null>('');
    const [commissionType, setCommissionType] = useState<CommissionsType | ''>('');

    if (!data) {
        return <p>Loading...</p>
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'flex-start',
            gap: '20px'
        }}>
            <h2
                style={{
                    'textAlign': 'start'
                }}
            >
                Комиссии
            </h2>

            <div
                style={{
                    display: 'flex', gap: '20px'
                }}
            >
                <ClientCodeInput
                    value={clientCode}
                    onChange={(value: string) => setClientCode(value)}
                    label="Код клиента"
                />
            </div>

            {
                !clientCode
                    ? <p>Выберите клиента</p>
                    : <ClientCommissions clientCode={clientCode} commissionType={commissionType === '' ? undefined : commissionType} />
            }

        </div>
    )
}
